<template>
  <viewcard--c
    :title="$route.params.id > 0 ? 'Atualizar Pergunta' : 'Cadastrar Pergunta'"
    :btsave="$route.params.id > 0 ? btedit : btcreate"
    :btdelete="null"
    :btback="{}"
    :busy="loading"
    @clicked-save="validationForm"
    @clicked-delete="
      {
      }
    "
  >
    <hr class="p-0 m-0 mb-1" />
    <validation-observer ref="questionsRules">
      <b-form @submit.prevent>
        <b-row>
          <b-col md="6">
            <b-form-group label="Nome *">
              <validation-provider
                #default="{ errors }"
                name="Nome da pergunta"
                rules="required"
              >
                <b-form-input
                  v-model="record.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nome da pergunta"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Ativo">
              <b-form-checkbox
                class="custom-control-success mt-1"
                name="check-button"
                switch
                v-model="record.active"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="3" md="3" class="mt-1">
            <b-form-group label="Tipo *">
              <validation-provider
                #default="{ errors }"
                name="Tipo"
                rules="required"
              >
                <v-select
                  v-if="types.length > 0"
                  v-model="typeSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="types"
                  autocomplete="off"
                  :clearable="false"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <div v-if="typeSelected && typeSelected.value == '0'">
          <b-row>
            <b-col cols="8" md="8" class="mt-1">
              <b-form-group label="Opção">
                <b-form-input v-model="option" />
              </b-form-group>
            </b-col>
            <b-col cols="1" md="1" class="mt-3">
              <b-button
                variant="info"
                @click="addOpcao()"
                :id="`adicionar-opcao`"
                size="sm"
              >
                <feather-icon icon="PlusIcon" />
                <b-tooltip
                  triggers="hover"
                  :target="`adicionar-opcao`"
                  title="Adicionar Opção"
                  :delay="{ show: 100, hide: 50 }"
                />
              </b-button>
            </b-col>
          </b-row>
          <b-row v-if="itens_tabela.length > 0">
            <b-col cols="8" md="8">
              <b-table
                :items="itens_tabela"
                :fields="fields"
                :busy="loading"
                responsive
                hover
                class="mt-1"
              >
                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      @click="removeList(data.item)"
                      icon="TrashIcon"
                      class="mx-1"
                      size="22"
                    />
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </div>
      </b-form>
    </validation-observer>
  </viewcard--c>
</template>
<script>
import _questionsService from "@/services/audiovisual/questions-service";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import { required } from "@validations";
import { BFormFile } from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormFile,
  },
  data() {
    return {
      btedit: {
        permission: `permission.audiovisual.demands.checklist.questions.edit`,
      },
      btcreate: {
        permission: `permission.audiovisual.demands.checklist.questions.create`,
      },
      btdelete: {
        permission: `permission.audiovisual.demands.checklist.questions.delete`,
      },
      loading: false,
      record: {
        id: 0,
        name: "",
        active: true,
        type_question: 0,
      },
      option: "",
      types: [],
      typeSelected: { value: "0", label: "Múltipla Escolha" },

      itens_tabela: [],
      fields: [
        { key: "name", label: "Opção" },
        { key: "actions", label: "Ações" },
      ],
    };
  },
  created() {
    localize("pt_BR", pt_br);
    this.getTypes();
  },
  mounted() {
    this.getRecord();
  },
  methods: {
    validationForm() {
      this.$refs.questionsRules.validate().then((success) => {
        if (success) {
          this.save();
        }
      });
    },
    getRecord() {
      if (this.$route.params.id > 0) {
        this.loading = true;
        _questionsService
          .find(this.$route.params.id)
          .then((res) => {
            this.record = res.content;

            if (this.record.type_question) {
              const type_question = this.types.filter(
                (f) => f.value === this.record.type_question
              );
              if (type_question && type_question.length > 0) {
                this.typeSelected = type_question[0];
              }
            }

            //Opção
            if (
              this.record.questionOptions &&
              this.record.questionOptions.length > 0
            ) {
              this.itens_tabela = [];
              this.itens_tabela.push(
                ...this.record.questionOptions.map((m) => {
                  return {
                    id: m.id,
                    name: m.name,
                  };
                })
              );
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.loading = false));
      }
    },
    save() {
      if (
        this.$utils.getValueSelected(this.typeSelected) == 0 &&
        (this.itens_tabela == null || this.itens_tabela.length == 0)
      ) {
        this.$utils.toastError(
          "Notificação",
          "Necessário informar pelo menos uma opção."
        );
      } else {
        const payload = {
          data: {
            name: this.record.name,
            id: this.$route.params.id,
            active: this.record.active,
            type_question: this.$utils.getValueSelected(this.typeSelected),
          },
        };

        payload.data.questionoptions = [];
        if (payload.data.type_question == 0) {
          if (this.itens_tabela) {
            this.itens_tabela.forEach((element) => {
              payload.data.questionoptions.push({
                name: element.name,
              });
            });
          }
        }
        //promisse
        const _createOrUpdate =
          this.record.id <= 0
            ? _questionsService.create(payload)
            : _questionsService.update(payload);

        this.loading = true;
        _createOrUpdate
          .then(() => {
            this.$utils.toast("Notificação", "Registro salvo com sucesso.");
            this.$router.go(-1);
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.loading = false));
      }
    },
    getTypes() {
      _questionsService
        .types()
        .then((res) => {
          this.types = this.$utils.populardropdown(
            res,
            "name",
            "id",
            false,
            false
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    addOpcao() {
      if (this.option) {
        let limpar = false;

        const name_selected = this.itens_tabela.filter(
          (f) => f.name === this.option
        );
        if (name_selected.length > 0) {
          limpar = true;
          this.$utils.toastError("Notificação", "Opção já adicionado.");
        } else {
          limpar = true;
          this.itens_tabela.push({
            name: this.option,
          });
        }
        if (limpar) {
          this.option = "";
        }
      }
    },
    removeList(item) {
      const new_list = this.itens_tabela.filter((f) => f.name !== item.name);
      this.itens_tabela = new_list;
    },
  },
};
</script>